<template>
  <div class="backlog-list">
    <el-table :data="tableData" height="300px" empty-text="暂无待办事项" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
      <el-table-column prop="departmentName" align="center" label="项目名称" show-overflow-tooltip></el-table-column>
      <el-table-column prop="location" align="center" label="吊篮位置" show-overflow-tooltip></el-table-column>
      <el-table-column prop="commitUserName" align="center" label="提交人" show-overflow-tooltip></el-table-column>
      <el-table-column prop="commitTime" align="center" label="提交时间" show-overflow-tooltip></el-table-column>
      <el-table-column label="操作" width="60">
        <template slot-scope="{ row }">
          <el-link type="primary" :underline="false" @click="audit(row)">审批</el-link>
        </template>
      </el-table-column>
    </el-table>
    <!-- 审批查看详情 -->
    <el-dialog
        title="审批吊篮详情"
        v-if="isShowAuditDetails"
        :visible.sync="isShowAuditDetails"
        width="50%">
      <el-form :inline="true" :model="editForm" class="demo-form-inline" label-width="120px">
        <div class="titleborder">基本信息</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮位置">
              <el-input v-model="editForm.location" placeholder="请输入吊篮位置" disabled=""></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="检查通知人">
              <el-input v-model="editForm.userNameList" placeholder="请输入检查通知人" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮型号">
              <el-input v-model="editForm.model" placeholder="请输入吊篮型号" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="额定荷载（KG）">
              <el-input v-model="editForm.ratedLoad" placeholder="请输入额定荷载" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮类型">
              <el-select v-model="editForm.hangingBasketType" placeholder="请选择吊篮类型" disabled>
                <el-option
                    v-for="item in options2"
                    :key="item.value + 'bbb'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="悬吊平台尺寸">
              <el-input v-model="editForm.platformSize" placeholder="请输入悬吊平台尺寸" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="安装方式">
              <el-select v-model="editForm.installWay" placeholder="请选择安装方式" disabled>
                <el-option
                    v-for="item in options1"
                    :key="item.value + 'ccc'"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="吊篮厂商">
              <el-input v-model="editForm.vendor" placeholder="请输入吊篮厂商" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="维修工名称">
              <el-input v-model="editForm.siteHead" placeholder="请输入维修工名称" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="维修工电话" prop="siteHeadMobile">
              <el-input v-model="editForm.siteHeadMobile" placeholder="请输入维修工电话" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="项目安全员" prop="safetyOfficerName" >
              <el-input v-model="editForm.safetyOfficerName" disabled placeholder="请选择项目安全员"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全员电话" prop="safetyOfficerMobile" disabled>
              <el-input v-model="editForm.safetyOfficerMobile" disabled placeholder="安全员电话"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="吊篮编码">
              <el-input v-model="editForm.coding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="安全检测合格证">
              <img v-if="editForm.safetyCertificate" :src="editForm.safetyCertificate" class="el-upload--picture-card">
              <div v-else class="flex-center el-upload--picture-card">暂无图片</div>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="titleborder">属性</div>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="编制人">
              <el-input v-model="editForm.makeName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制日期">
              <el-input v-model="editForm.makeTime" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="单据编码">
              <el-input v-model="editForm.documentsCoding" placeholder="系统自动生成" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="编制机构">
              <el-input v-model="editForm.departmentName" disabled></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="11">
            <el-form-item label="双方协调确认单">
              <div style="width: 220px;text-align: left;">
                <el-link type="primary" :href="editForm.confirmationSheet" target="_blank">双方协调确认单</el-link>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="AuditConfirm" size="small">确 认</el-button>
        <el-button type="primary" @click="AuditRefused" size="small">打 回</el-button>
        <el-button @click="isShowAuditDetails = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 查看更多 -->
    <el-dialog title="待办列表" :visible.sync="isShowMore" width="50%">
      <div class="cont">
        <el-table :data="tableMoreList" :header-cell-style="{background:'#f5f5f5',color:'#333333'}">
          <el-table-column prop="departmentName" align="center" label="项目名称" show-overflow-tooltip></el-table-column>
          <el-table-column prop="location" align="center" label="吊篮位置" show-overflow-tooltip></el-table-column>
          <el-table-column prop="commitUserName" align="center" label="提交人" show-overflow-tooltip></el-table-column>
          <el-table-column prop="commitTime" align="center" label="提交时间" show-overflow-tooltip></el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="{ row }">
              <el-link type="primary" :underline="false" @click="audit(row)">审批</el-link>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex-center" style="padding-top:10px;">
          <el-pagination
              background
              :current-page.sync="moreForm.pageCurrent"
              :page-size="moreForm.pageSize"
              :total="total"
              layout="total, prev, pager, next"
              @current-change="handleSizeChangeReocrdData"
          ></el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowMore = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import apis from '../api/nacelle-set.js'
export default {
  name: "backlog-list",

  components: {},

  props: {},

  data() {
    return {
      tableData: [],
      SearchValue: {
        pageCurrent: 1,
        pageSize: 5,
        location: '',
        model: '',
        hangingBasketType: '',
        departmentCode: '',
        doStatus: 2
      },
      editForm: {},
      isShowAuditDetails: false,
      tableMoreList: [],
      isShowMore: false,
      moreForm: {
        pageCurrent: 1,
        pageSize: 10,
        location: '',
        model: '',
        hangingBasketType: '',
        departmentCode: '',
        doStatus: 2
      },
      total: 0
    }
  },

  computed: {
    ...mapGetters([
      'companyId',
      'optionItem',
      'userInfo'
    ]),
  },

  watch: {},

  mounted() {
    // this.init()
  },

  methods: {
    onRefresh() {
      this.SearchValue.pageCurrent = 1
      this.init()
    },
    async init() {
      this.SearchValue.departmentCode = this.companyId
      const data = await apis.findAllDl(this.SearchValue)
      this.tableData = data.list
    },
    async audit(row) {
      // const isOperate = this.optionItem.permissionNameList.includes('hanging_basket_view')
      // if (!isOperate) return this.$message.error('请联系管理员添加查看权限')
      const data = row
      await apis.findByBasketId({id:data.id}).then(async res=>{
        this.editForm= res
        this.isShowAuditDetails = true
      })
    },
    // 审批通过
    AuditConfirm() {
      apis.confirmDoStatus(this.editForm.id).then(()=>{
        this.$message.success(`审批成功`)
        this.init()
        if (this.isShowMore) this.showMore()
        this.isShowAuditDetails = false
        this.isShowMore = false
      })
    },
    // 审批打回
    AuditRefused() {
      apis.confirmBack(this.editForm.id).then(()=>{
        this.$message.success(`已经打回`)
        this.init()
        if (this.isShowMore) this.showMore()
        this.isShowAuditDetails = false
        this.isShowMore = false
      })
    },
    showMore() {
      this.moreForm.pageCurrent = 1
      this.initMoreTable()
      this.isShowMore = true
    },
    // 切换记录页码
    async handleSizeChangeReocrdData(val) {
      // console.warn(val)
      this.moreForm.pageCurrent = val
      this.initMoreTable().async
    },
    async initMoreTable() {
      this.moreForm.departmentCode = this.companyId
      const data = await apis.findAllDl(this.moreForm)
      this.tableMoreList = data.list
      this.total = data.total
    }
  }
}
</script>

<style lang="stylus" scoped>
.backlog-list {
  //flex 1
}
</style>
